export enum AppRoutes {
    LOGIN = "login",
    CONNECT = "connect",
    TRIP = "trip",
    HOMEPAGE = "homepage",
    ADVISOR = "advisor",
    CLIENT = "client",
    MENU = "menu",
    MENUITI = "menuiti",
    MENUFP = "menufp",
    MENUBIWEB = "menubiweb",
    MENUDEVISWEB = "menudevisweb",
    VIDEO = "video",
}

export const DEFAULT_ROUTE = AppRoutes.HOMEPAGE;
