import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AppRoutes, DEFAULT_ROUTE } from "@app/routes";
import { CanActivateUser } from "@core/guards";

const routes: Routes = [
    {
        loadChildren: () => import("./login/login.module").then((m) => m.LoginModule),
        path: AppRoutes.LOGIN,
    },
    {
        loadChildren: () => import("./connect/connect.module").then((m) => m.ConnectModule),
        path: AppRoutes.CONNECT,
    },
    {
        loadChildren: () => import("./homepage/homepage.module").then((m) => m.HomepageModule),
        path: AppRoutes.HOMEPAGE,
    },
    {
        loadChildren: () => import("./advisor/advisor.module").then((m) => m.AdvisorModule),
        path: AppRoutes.ADVISOR,
    },
    {
        canActivate: [CanActivateUser],
        loadChildren: () => import("./trip/trip.module").then((m) => m.TripModule),
        path: AppRoutes.TRIP,
    },
    {
        loadChildren: () => import("./client/client.module").then((m) => m.ClientModule),
        path: AppRoutes.CLIENT,
    },
    {
        loadChildren: () => import("./menu/menu.module").then((m) => m.MenuModule),
        path: AppRoutes.MENU,
    },
    {
        loadChildren: () => import("./menu-itinerary/menu.itinerary.module").then((m) => m.MenuItineraryModule),
        path: AppRoutes.MENUITI,
    },
    {
        loadChildren: () => import("./menu-flight-plan/menu-flight-plan.module").then((m) => m.MenuFlightPlanModule),
        path: AppRoutes.MENUFP,
    },
    {
        loadChildren: () => import("./menu-devis-web/menu.module").then((m) => m.MenuDevisWebModule),
        path: AppRoutes.MENUDEVISWEB,
    },
    {
        loadChildren: () => import("./menu-bi-web/menu-bi.module").then((m) => m.MenuBiWebModule),
        path: AppRoutes.MENUBIWEB,
    },
    {
        loadChildren: () => import("./multimedia/multimedia.module").then((m) => m.MultimediaModule),
        path: AppRoutes.VIDEO,
    },

    {
        path: "**",
        redirectTo: DEFAULT_ROUTE,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
